//TODO

import { enUS } from "date-fns/locale";

import { BookingCardColor } from "@/types/booking";
import { OrderRiskLevel, RiskLevel } from "@/types/order";
import { DateRangeOption } from "@/types/store";

export const LOGO_URL_WHITE = "https://cdn.per-diem.co/logos/logo-white.svg";
export const LOGO_URL_BLUE = "https://cdn.per-diem.co/logos/logo-blue.svg";
export const LOGO_URL_BLACK = "https://cdn.per-diem.co/logos/logo-black.svg";
export const TEST_IMAGE =
  "https://cdn.per-diem.co/staging/wl/logos/3ba3b6d4-c367-45dc-ade0-703ba78b8ebe.1702010313713.png";
export const PLACEHOLDER_IMG = "https://cdn.per-diem.co/assets/no_image.png";

export const SUPPORT_EMAIL = "support@tryperdiem.com";

export const CALENDLY_LINK =
  "https://calendly.com/per-diem/per-diem-product-demo";

export const PROVIDERS = {
  SQUARE: "Square",
  TOAST: "Toast",
  CLOVER: "Clover",
};
export const QUERY_PARAMS = {
  pagination: "offset",
  name: "name",
  status: "status",
  orderBy: "orderBy",
  type: "type",
};
export const BookingStatusList = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  CANCELED: "CANCELED",
};
export const DISCOUNT_TYPE = {
  FIXED: "fixed",
  PERCENTAGE: "percentage",
};
export const STORE_CURRENCIES = ["USD", "EUR", "CAD", "GBP", "AUD"];
export const STORE_COUNTRIES = [
  {
    name: "United States",
    code: "US",
    currency: "USD",
  },
  {
    name: "France",
    code: "FR",
    currency: "EUR",
  },
  {
    name: "Canada",
    code: "CA",
    currency: "CAD",
  },
  {
    name: "United Kingdom",
    code: "GB",
    currency: "GBP",
  },
  {
    name: "Australia",
    code: "AU",
    currency: "AUD",
  },
];
export const INVALID_PHONE_ERROR_MESSAGE =
  "Please enter a valid phone number with or without a country code (e.g: +11234567890 or 1234567890";
export const locales = {
  "en-US": enUS,
};

export const ACTIVE_TAB_COLOR =
  "invert(42%) sepia(25%) saturate(6455%) hue-rotate(202deg) brightness(88%) contrast(88%)";
export const entityStatus = {
  ACTIVE: {
    key: "active",
    value: "Active",
    label: "Active",
  },
  INACTIVE: {
    key: "inactive",
    value: "Inactive",
    label: "Inactive",
  },
};

export const SQUARE_PRICING_TYPES = {
  VARIABLE_PRICING: "VARIABLE_PRICING",
  FIXED_PRICING: "FIXED_PRICING",
};

export const DAYS_OF_THE_WEEK: { [key: number]: string } = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const OFFSET = 1;

export const LIMIT = 50;

export const CONSUMER_NOTIFICATION_TYPES = {
  ANNOUNCEMENTS: "announcements",
  POP_UP: "startup_pop_up",
  DISCOUNTS: "discounts",
  SCHEDULED_BANNER: "scheduled",
};

export const CONSUMER_NOTIFICATION_IMAGE_TYPES = {
  ICON: "icon",
  IMAGE: "image",
  VIDEO: "video",
};

export const DEFAULT_CURRENCY = "USD";
export const CURRENCY_SIGNS = {
  USD: "$",
  CAD: "CA$",
  AUD: "A$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
};
export const DEBOUNCE_TIME = 500;
export const USER_TYPES = {
  INTERNAL: "internal",
  PUBLIC: "consumer",
  MERCHANT: "merchant",
};

export const USER_ACCESS_LEVEL = {
  OWNER: "owner",
  GENERAL_MANAGER: "general_manager",
  MANAGER: "manager",
  EMPLOYEE: "employee",
};

export const PERMISSIONS = {
  "storeCredit:create": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "storeCredit:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "customer:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "fees&Tips:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "featureFlag:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "transaction:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "emailSettings:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "permissionSettings:update": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.GENERAL_MANAGER,
  ],
  "rewards:update": [USER_ACCESS_LEVEL.OWNER, USER_ACCESS_LEVEL.MANAGER],
  "notificationTemplate:create": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
  "notificationTemplate:update": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
  "posts:create": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.GENERAL_MANAGER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
  "posts:update": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.GENERAL_MANAGER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
  "banner:create": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.GENERAL_MANAGER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
  "banner:update": [
    USER_ACCESS_LEVEL.OWNER,
    USER_ACCESS_LEVEL.GENERAL_MANAGER,
    USER_ACCESS_LEVEL.MANAGER,
  ],
};
export const MAX_IMAGE_SIZE = 1.5e6;
export const UPLOAD_TYPES = {
  LOGO: "logo",
  STORE_CREDIT_IMAGE: "store_credit_image",
  ONBOARDING_IMAGE: "onboarding_image",
  HOME_PAGE_IMAGE: "home_page_image",
  REWARDS_ICON: "rewards_icon",
  POST_IMAGE: "post_image",
  BANNER_IMAGE: "banner_image",
};
export const IMAGE_DIMENSIONS = {
  SRORE_CREDIT_IMAGE_DIMENSION: { width: 375, height: 812 },
  BRAND_LOGO_DIMENSION: { width: 150, height: 150 },
  ONBOARDING_CARD_DIMENSION: { width: 500, height: 500 },
  HOME_PAGE_IMAGE: { width: 500, height: 500 },
  REWARD_CARD_DIMENSION: { width: 150, height: 150 },
  POST_IMAGE: { width: 512, height: 512 },
  IMAGE_BACKGROUND: { width: 512, height: 512 },
};

export const ORDER_TYPES = {
  STORE_CREDIT_RELOAD: "store_credit_reload",
  GIFT_CARD: "gift_card",
  SUBSCRIPTION_PURCHASE: "subscription_purchase",
  REGULAR_CHECKOUT: "regular_checkout",
};
export const TERM_LINK = "https://www.per-diem.co/terms-and-conditions";
export const PRIVACY_LINK = "https://www.per-diem.co/privacy-policy";

export const CUSTOM_VERTICAL_TEXT = "custom";
export const BUSINESS_VERTICALS = {
  "barber-shop": "Barber Shop",
  "hair-salon": "Hair Salon",
};
export const ONBOARDING_TABS_DATA = [
  {
    tabName: "Slide 1",
    key: "slide-1",
  },
  {
    tabName: "Slide 2",
    key: "slide-2",
  },
  {
    tabName: "Slide 3",
    key: "slide-3",
  },
];

export const appCustomizationValues = {
  primary_color: "#00000",
  settingTab: "AppLogoStyle",
  defaultSlides: [
    {
      heading: "Order ahead",
      body: "Finding your nearest location and placing an order is easier than ever",
      image_url: "",
      active: true,
    },
    {
      heading: "Get rewards",
      body: "Keep track of your loyalty points balance as well as any rewards currently stored in your wallet for redemption",
      image_url: "",
      active: true,
    },
    {
      heading: "Be a regular",
      body: "View your order history and 'order again' with the tap of a button",
      image_url: "",
      active: true,
    },
  ],
};
export const customerBlockInfoSelectData = [
  {
    key: "Fraudulent Activity",
    value: "Fraudulent Activity",
    label: "Fraudulent Activity",
  },
  {
    key: "excessive returns or refunds",
    value: "Excessive Returns or Refunds",
    label: "Excessive Returns or Refunds",
  },
  {
    key: "Non-Payment or Payment Disputes",
    value: "Non-Payment or Payment Disputes",
    label: "Non-Payment or Payment Disputes",
  },
  {
    key: "Suspicious Activity",
    value: "Suspicious Activity",
    label: "Suspicious Activity",
  },
  {
    key: "Other",
    value: "Other",
    label: "Other",
  },
];

export const SendToOptions = {
  ALL_CUSTOMERS: "all_customers",
  CUSTOMER_GROUPS: "customer_groups",
};

export const tipsSelectData = [
  {
    label: `None`,
    key: `none`,
    value: `none`,
  },
  {
    label: `Tipping option 1`,
    key: `0`,
    value: "0",
  },
  {
    label: `Tipping option 2`,
    key: `1`,
    value: "1",
  },
  {
    label: `Tipping option 3`,
    key: `2`,
    value: "2",
  },
];
export const LOG_TABS = {
  SERVICES: "services",
  STAFF: "staff",
  LOCATION: "locations",
};
export const LOGS_LIMIT = 10;
export const SQUARE_CUSTOMERS_URL = "https://squareup.com/dashboard/customers";
export const dotChipData = {
  default: { text: "Default", textColor: "#1C1C1C66", dotColor: "#1C1C1C66" },
  owner: { text: "Owner", textColor: "#4AA785", dotColor: "#A1E3CB" },
  active: { text: "Active", textColor: "#4AA785", dotColor: "#A1E3CB" },
  deactivated: {
    text: "Deactivated",
    textColor: "#FF4747",
    dotColor: "#FF4747",
  },
  manager: { text: "Manager", textColor: "#8A8CD9", dotColor: "#95A4FC" },
  employee: { text: "Employee", textColor: "#FFC555", dotColor: "#FFE999" },
  expired: { text: "Expired", textColor: "#1C1C1C66", dotColor: "#1C1C1C66" },
  canceled: {
    text: "Canceled",
    textColor: "#FF4747CC",
    dotColor: "#FF4747CC",
  },
  no_subscription: {
    text: "No subscription",
    textColor: "#1C1C1C66",
    dotColor: "#1C1C1C66",
  },
  Pending: {
    text: "Pending",
    textColor: "#1C1C1CCC",
    dotColor: "#1C1C1CCC",
  },
  Normal: {
    text: "Normal",
    textColor: "#4AA785",
    dotColor: "#4AA785",
  },
  Moderate: {
    text: "Moderate",
    textColor: "#FFA500",
    dotColor: "#FFA500",
  },
  High: {
    text: "High",
    textColor: "#FF4747",
    dotColor: "#FF4747",
  },
};

export const USER_PRIVILEGES = {
  owner: ["owner", "manager", "employee"],
  manager: ["manager", "employee"],
  employee: ["employee"],
};

export const FEATURE_FLAGS = {
  //Authentication
  "Social authentication": "social_auth",
  "Apple Login": "apple_login",
  "Google Login": "google_login",
  "Require email on authentication": "require_email",
  //Booking preferences
  "Booking note": "booking_note",
  "Bad Review Alert": "bad_review_alert",
  "Apply Square Discounts": "apply_square_discounts",
  //Customization
  "Show birthday (Input Text)": "show_birthday_input",
  "Customer support emailing": "customer_support_emailing",
  //rest
  "Google Review Link": "google_review",
  Loyalty: "loyalty",
};
export const FEATURE_FLAGS_EXPLANATION: {
  [key: string]: string;
} = {
  //Authentication
  apple_login:
    "Customers will be able to sign-up and sign-in using their Apple Account",
  google_login:
    "Customers will be able to sign-up and sign-in using their Google Account",
  //Booking preferences
  booking_note: "Allow customers add notes when booking.",
  bad_review_alert:
    "Notify Merchants when a customer leaves a bad review on their last order.",
  apply_square_discounts:
    "This setting automatically applies any Square discounts associated with a product when it's added to a checkout in the mobile app.",
  //Customization
  show_birthday_input:
    "Allow customers to add their birthday to the mobile app.",
  customer_support_emailing:
    "Allow customers to send a message to the Merchant's support email",
  loyalty:
    "Allow customers earn and use points on your mobile app. Points can also be used to get discounts and other benefits on the app.",
};
export const dateFormatSelectionData = [
  {
    label: `mm/dd/yyyy`,
    key: `MM/dd/yyyy`,
    value: `MM/dd/yyyy`,
  },
  {
    label: `dd/mm/yyyy`,
    key: `dd/MM/yyyy`,
    value: `dd/MM/yyyy`,
  },
  {
    label: `dd month, year`,
    key: `dd MMM yyyy`,
    value: `dd MMM yyyy`,
  },
  {
    label: `month dd, year`,
    key: `MMM dd yyyy`,
    value: `MMM dd yyyy`,
  },
];

export const distanceUnitSelectionData = [
  {
    label: `Miles`,
    key: `miles`,
    value: `miles`,
  },
  {
    label: `Kilometers`,
    key: `kilometers`,
    value: `kilometers`,
  },
  {
    label: `Meters`,
    key: `meters`,
    value: `meters`,
  },
];
export const defaultPurchaseLimitData = {
  amount_limit_in_cents: 25000,
  interval_in_hours: 48,
  notify: true,
  block: false,
  enabled: true,
};
export const PurchaseLimitIntervals = [24, 48, 72].map((interval) => ({
  key: `${interval}`,
  value: `${interval}`,
  label: `${interval} hours`,
}));

export const PUSH_NOTIFICATIONS_TITLE_DESCRIPTIONS = {
  booking_confirmation: {
    title: "Booking Confirmation",
    description: "Inform your customers once you receive their booking.",
  },
  appointment_reminder: {
    title: "Appointment Reminder",
    description: "Remind your customers prior to their appointment.",
  },
  post_service_confirmation: {
    title: "Post Service Confirmation",
    description: "Thank your customers for keeping their appointment.",
  },
  incomplete_booking: {
    title: "Incomplete Booking",
    description: "Get your customers to finish incomplete bookings",
  },
};

export const NEW_POST_LINK_TO_OPTIONS = [
  { key: "app_page", label: "App Page", value: "App Page" },
  { key: "external_url", label: "External URL", value: "External URL" },
  { key: "store_credit", label: "Store Credit", value: "Store Credit" },
];

export const postType = {
  EXTERNAL_URL: "external_url",
  STORE_CREDIT: "store_credit",
  APP_PAGE: "app_page",
} as const;

export const NEW_POST_PAGE_OPTIONS = [
  {
    value: "Home",
    label: "Home",
    key: "home",
  },
  {
    value: "Feed",
    label: "Feed",
    key: "feed",
  },
  {
    value: "Appointments",
    label: "Appointments",
    key: "appointments",
  },
  {
    value: "Services",
    label: "Services",
    key: "services",
  },
  {
    value: "Store Credit",
    label: "Store Credit",
    key: "store_credit",
  },
  {
    value: "Profile",
    label: "Profile",
    key: "profile",
  },
  {
    value: "Waitlist",
    label: "Waitlist",
    key: "waitlist",
  },
  {
    value: "Buy an eGift Card",
    label: "Buy an eGift Card",
    key: "buy_egift_card",
  },
  {
    value: "Redeem an eGift Card",
    label: "Redeem an eGift Card",
    key: "redeem_egift_card",
  },
  {
    value: "Payment Methods",
    label: "Payment Methods",
    key: "payment_methods",
  },
  {
    value: "Settings",
    label: "Settings",
    key: "settings",
  },
  {
    value: "Help and feedback",
    label: "Help and feedback",
    key: "help",
  },
];

export const BOOKING_VERTICAL = "booking";
export const SQUARE_LOYALTY_PROGRAM_URL =
  "https://squareup.com/dashboard/loyalty";

export const initialIncentivesData = {
  active: false,
  signup: {
    incentive_type: "points",
    points: 0,
    active: false,
  },
  checkout: {
    incentive_type: "points",
    points: 0,
    active: false,
  },
  first_checkout: {
    incentive_type: "points",
    points: 0,
    active: false,
  },
};

export const DEFAULT_COLORS = {
  primary: "#0171EA",
  secondary: "#FFFFFF",
  secondary_text: "#000000",
};
export const DISCOUNT_SELECT_DATA = [
  {
    key: "fixed",
    label: "$",
    value: "fixed",
  },
  {
    key: "percentage",
    label: "%",
    value: "percentage",
  },
];
export const ALL_STATUS_TEXT = "All Statues";
export const ALL_STATUS_KEY = "all_statues";
export const ACTIVE_STATUS_KEY = "active";
export const ALL_TYPES_TEXT = "All type";
export const ALL_TYPES_KEY = "all_types";
export const ALL_CUSTOMERS_TEXT = "All customers";
export const ALL_CUSTOMERS_KEY = "all_customers";
export const EXPIRED_STATUS_TEXT = "expired";
export const EXPIRED_STATUS_LABEL = "Expired";
export const ALL_RISK_TEXT = "All Risk";
export const ALL_RISK_KEY = "all_risk";
export const RISK_LEVELS: Record<OrderRiskLevel, RiskLevel> = {
  0: {
    text: "Pending",
  },
  1: {
    text: "Normal",
  },
  2: {
    text: "Moderate",
  },
  3: {
    text: "High",
  },
};
export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  {
    key: "all_time",
    label: "All time",
  },
  {
    key: "today",
    label: "Today",
  },
  {
    key: "yesterday",
    label: "Yesterday",
  },
  {
    key: "this_week",
    label: "This week",
  },
  {
    key: "last_week",
    label: "Last week",
  },
  {
    key: "this_month",
    label: "This month",
  },
  {
    key: "last_month",
    label: "Last month",
  },
  {
    key: "this_year",
    label: "This year",
  },
  {
    key: "last_year",
    label: "Last year",
  },
  {
    key: "custom",
    label: "Custom",
  },
];
export const EMAIL_SETTINGS_KEYS = {
  BCC_OUTGOING_EMAILS: "bcc_outgoing_emails",
  NOTIFICATION_EMAILS: "notification_emails",
};
export const DEFAULT_BOOKING_CARD_COLOR: BookingCardColor = {
  confirmedColor: "#008743",
  unConfirmedColor: "#DFA123",
  cancelledColor: "#B10E1C",
};
