// eslint-disable-next-line import/named
import { CalendarDate } from "@internationalized/date";
import { AxiosError, isAxiosError } from "axios";
import { NextResponse } from "next/server";
import url from "url";

import {
  CURRENCY_SIGNS,
  DEFAULT_CURRENCY,
  LIMIT,
  OFFSET,
} from "@/config/constant";
import { Customer, Staff, UserType } from "@/types";

type Currency = keyof typeof CURRENCY_SIGNS;
type QueryParam = {
  [key: string]: string | number;
};

export const getQueryParams = (request: Request, includeExtraParams = true) => {
  const params = url.parse(request.url, true).query;
  return {
    ...params,
    ...(includeExtraParams && {
      limit: Number(params.limit || LIMIT),
      offset: ((Number(params.offset) || OFFSET) - 1) * LIMIT,
    }),
  } as QueryParam;
};
const customMessage: Record<string, string> = {
  'body/phone must match pattern "^(\\+?1\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$|^(\\+?44\\s?)?\\(?\\d{2}\\)?[\\s.-]?\\d{4}[\\s.-]?\\d{4}$|^(\\+?33\\s?)?\\(?\\d{1}\\)?[\\s.-]?\\d{2}[\\s.-]?\\d{2}[\\s.-]?\\d{2}[\\s.-]?\\d{2}$|^(\\+?61\\s?)?\\(?\\d{1}\\)?[\\s.-]?\\d{4}[\\s.-]?\\d{4}$"':
    "Phone number is not valid",
};
export const getErrorMessage = (error: unknown): string => {
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError & {
      response: { data: { message?: string; error?: string } };
    };
    const message = String(
      axiosError.response?.data?.message ||
        axiosError.response?.data?.error ||
        axiosError.response?.data ||
        axiosError.message,
    );
    return customMessage[message] || message;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return "Error";
};

export function createErrorResponse(error: unknown): NextResponse {
  const status =
    (error as { response: { status: number } })?.response?.status ?? 500;

  return NextResponse.json(
    { message: getErrorMessage(error) },
    { status: status },
  );
}

export const clearUndefinedAndNull = <T>(obj: { [key: string]: unknown }) => {
  const cleanObj = {} as { [key: string]: unknown };
  Object.entries(obj).forEach(([key, value]) => {
    if (
      typeof value === "number" ||
      (typeof value === "string" && value.length) ||
      typeof value === "boolean" ||
      (typeof value === "object" &&
        value !== null &&
        Object.entries(value as { [key: string]: unknown }).length)
    ) {
      cleanObj[key] = value;
    }
  });
  return cleanObj as T;
};

export const getFromLocalStorage = <T>(key: string): T | null => {
  if (typeof window === "undefined") {
    return null;
  }

  const data = localStorage.getItem(key);

  if (!data || data === "undefined") {
    return null;
  }

  try {
    return JSON.parse(data) as T;
  } catch {
    return data as T;
  }
};

export function formatDuration(
  durationInMilliseconds: number | undefined,
): string {
  if (!durationInMilliseconds) return "";
  const minutes = Math.floor(durationInMilliseconds / 60000);
  return `${minutes} min`;
}

export const centsToDollars = (cents = 0): string => {
  return "$" + (Math.round(cents) / 100).toFixed(2);
};

export const formatCurrency = (
  amount = 0,
  currency: Currency = DEFAULT_CURRENCY,
): string => {
  currency = currency ?? DEFAULT_CURRENCY;
  const sign = CURRENCY_SIGNS[currency];
  if (currency === "JPY") {
    return sign + amount;
  }
  return sign + (Math.round((Number(amount) / 100) * 100) / 100).toFixed(2);
};

export const truncateText = (str: string, maxLength: number): string => {
  if (!str) return "";
  return `${str.substring(0, maxLength)} ${
    str.length > maxLength ? "..." : ""
  }`;
};

export const floatToFractionString = (number: number) => {
  function gcd(a: number, b: number): number {
    return b ? gcd(b, a % b) : a;
  }
  let numerator: number = Math.round(number * 1000000);
  let denominator = 1000000;
  const divisor: number = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  return { numerator, denominator };
};

export const getFullName = (user: Partial<Customer | Staff>): string => {
  return `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
};
export const getMimeTypeFromDataURL = (dataURL: string) => {
  const mime = dataURL.match(/^data:(.*?);base64,/)?.[1];
  return mime || "image/jpeg";
};
export const getUser = (user: UserType): UserType => {
  return {
    user_id: user?.user_id ?? null,
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    email: user?.email ?? "",
    phone: user?.phone ?? "",
    store_id: user?.store_id ?? null,
    store_ids: user?.store_ids ?? null,
    active: user?.active ?? false,
    type: user?.type,
    created_at: user.created_at,
    updated_at: user.updated_at,
    access_level: user?.access_level,
    storeInfo: user?.storeInfo,
    permissions: user?.permissions,
  };
};
export const centsToDollarsWithoutSymbol = (cents: number): number => {
  return Math.round(cents) / 100;
};

export function isNullOrEmpty(str: string | null | undefined) {
  return !str || str.length === 0;
}
export function getYouTubeVideoId(url: string): string {
  if (!url) return "";
  try {
    const urlObj = new URL(url);
    if (urlObj.hostname === "youtu.be") {
      // For short URLs like https://youtu.be/KP_A2ooJIYI
      return urlObj.pathname.slice(1);
    }
    if (
      urlObj.hostname === "www.youtube.com" ||
      urlObj.hostname === "youtube.com"
    ) {
      // For long URLs like https://www.youtube.com/watch?v=KP_A2ooJIYI
      return urlObj.searchParams.get("v") ?? "";
    }
    // default
    return "";
  } catch {
    return "";
  }
}
export const replaceAll = (str: string, sign = "_") => {
  return str.replaceAll(" ", sign).toLowerCase();
};
export const getCalendarDate = (date: Date | string | number) => {
  const d = new Date(date);
  return new CalendarDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
};
